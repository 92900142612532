.about-tower-container {
  box-shadow: 0 0 20px 0 rgb(0 0 0 / 10%);
}

.about-tower-margin {
  margin: 0 7rem;
}

@media (max-width: 576px) {
  .about-tower-margin {
    margin: 0 1.5rem;
  }
}

@media (min-width: 577px) and (max-width: 767px) {
}

@media (min-width: 768px) and (max-width: 991px) {
  .about-tower-margin {
    margin: 0 3rem;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .about-tower-margin {
    margin: 0 3rem;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
}

@media (min-width: 1400px) and (max-width: 1799px) {
}
