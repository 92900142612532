/* Smart Home Section */

.smart-home-header-img {
  max-width: 100%;
  height: 10rem;
}

/* Eco System Section */
.eco-system-img {
  width: 100%;
  /* height: 59.6rem; */
  height: 100%;
}

/* Agriculture About Section */
.agriculture-about-container {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.52),
      rgba(0, 0, 0, 0.73)
    ),
    url("../images/agriculture/Picture1.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* Product Range Section */
.product-range-container {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.52),
      rgba(0, 0, 0, 0.73)
    ),
    url("../images/agriculture/Picture3.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.product-img {
  max-width: 100%;
  height: 20rem;
}

/*  Dates Grower Section */
.dates-grower-container {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.52),
      rgba(0, 0, 0, 0.73)
    ),
    url("../images/agriculture/Picture13.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.dates-grower-img {
  max-width: 100%;
  height: 55rem;
}

/* Packaging Section */
.packaging-container {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.52),
      rgba(0, 0, 0, 0.73)
    ),
    url("../images/agriculture/Picture33.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* Our Brands Section */
.our-brands-container {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.52),
      rgba(0, 0, 0, 0.73)
    ),
    url("../images/agriculture/Picture34.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.our-brand-img-container {
  padding: 2rem;
  margin: 1rem 0rem 2.5rem 0rem;
}

.our-brand-content-container {
  margin: 1rem 0rem 0rem 0rem;
  padding: 0rem 2rem 1.5rem 2rem;
}

/* Fair Trade Quality Section */
.fair-trade-quality-container {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.52),
      rgba(0, 0, 0, 0.73)
    ),
    url("../images/agriculture/Picture20.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* Address Section */
.agriculture-address-img {
  width: 25%;
  height: auto;
}

.agriculture-address-container {
  width: 50%;
  margin: auto;
  padding: 2rem;
  border-radius: 2rem;
}

/* Digital Marketing Service Section */
.digital-marketing-service-card {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  padding: 3rem;
  height: 100%;
}

.digital-marketing-service-card-header {
  height: 25%;
  padding-top: 1rem;
}

.digital-marketing-service-card-img {
  width: 6rem;
}

.digital-marketing-clients {
  max-width: 100%;
}

.digital-marketing-card-height {
  height: 50rem;
}

.dm-contact-btn {
  transition: all 0.5s ease-in-out;
}

.dm-header-btn{
  transition: all 0.5s ease-in-out;
}

.dm-contact-btn:hover {
  background-color: #f49807;
  color: #ffffff !important;
}

.dm-header-btn:hover {
  background-color: #f49807;
}

.solar-alt-accelerate {
  background-image: url("../images/afro-solar/accesilarate-clean-energy/bg.webp");
  height: 80vh;
  background-size: cover;
}

.solar-microgrid-image-1 {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.52),
      rgba(0, 0, 0, 0.73)
    ),
    url("../images/afro-solar/solar-microgrids/first-option.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 20rem;
  width: 20rem;
}

.solar-microgrid-image-2 {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.52),
      rgba(0, 0, 0, 0.73)
    ),
    url("../images/afro-solar/solar-microgrids/second-option.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 20rem;
  width: 20rem;
}

.solar-microgrid-image-3 {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.52),
      rgba(0, 0, 0, 0.73)
    ),
    url("../images/afro-solar/solar-microgrids/third-option.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 20rem;
  width: 20rem;
}

/* Sample card design */
.card-design-1 {
  box-shadow: rgb(0 0 0 / 15%) 1.95px 1.95px 2.6px;
  transition: 0.6s all;
}

.card-design-1:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.card-design-2 {
  transition: all 0.6s ease-in-out;
}

.card-design-2:hover {
  transition: 0.6s all;
  transform: scale(1.1);
  cursor: pointer;
}

.card-design-3 {
  transition: all 0.6s ease-in-out;
}

.card-design-3:hover {
  transform: translate(0, -18px);
  cursor: pointer;
}

.digital-card {
  position: relative;
  z-index: 1;
}

.digital-card::before {
  content: "";
  width: 0.5rem;
  height: 100%;
  background-color: #efefef;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  transition: all 0.8s ease-in-out;
}

.digital-card:hover::before {
  width: 100%;
  cursor: pointer;
}

/* Responsive */
@media (max-width: 576px) {
  .smart-home-header-img {
    height: 5rem;
  }

  .dates-grower-img {
    height: auto;
  }

  .agriculture-address-container {
    width: 100%;
  }

  .agriculture-address-img {
    width: 100%;
  }

  .solar-alt-accelerate {
    height: auto;
  }

  .solar-alt-accelerate .px-8 {
    padding: 0rem 1rem;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .eco-system .me-13 {
    margin-right: 0rem;
  }

  .smart-home-system .ms-13 {
    margin-left: 0rem;
  }

  .machanical-electrical .me-13 {
    margin-right: 0rem;
  }

  .agriculture-address-container {
    width: 100%;
  }

  .agriculture-address-img {
    width: 75%;
  }

  .solar-alt-accelerate {
    height: auto;
  }

  .solar-alt-accelerate-height {
    height: 100%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .smart-home-solution .me-13 {
    margin-right: 2rem;
  }

  .smart-home-solution .ms-13 {
    margin-left: 2rem;
  }

  .eco-system .me-13 {
    margin-right: 2rem;
  }

  .smart-home-system .ms-13 {
    /* margin-left: 9.5rem; */
  }

  .machanical-electrical .me-13 {
    margin-right: 2rem;
  }

  .agriculture-address-container {
    width: 70%;
  }

  .agriculture-address-img {
    width: 40%;
  }

  .solar-alt-accelerate {
    height: auto;
  }

  .solar-alt-accelerate .px-8 {
    padding: 0rem 0rem;
  }

  .solar-alt-text {
    padding-bottom: 2.5rem !important;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .solar-alt-accelerate {
    height: auto;
  }
  .solar-alt-accelerate .px-8 {
    padding: 0rem 1rem;
  }
}

@media (min-width: 1400px) and (max-width: 1680px) {
  .solar-alt-accelerate .px-8 {
    padding: 0rem 1rem;
  }

  .solar-alt-text {
    padding-bottom: 2.5rem !important;
  }
}
