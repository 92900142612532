@import "./styles/font.css";
@import "./styles/common.css";
@import "./styles/button.css";
@import "./styles/navbar.css";
@import "./styles/header.css";
@import "./styles/whatWeDo.css";
@import "./styles/solution.css";
@import "./styles/approach.css";
@import "./styles/review.css";
@import "./styles/team.css";
@import "./styles/project.css";
@import "./styles/award.css";
@import "./styles/academy.css";
@import "./styles/newsLetter.css";
@import "./styles/ourCustomer.css";
@import "./styles/contactUs.css";
@import "./styles/blog.css";
@import "./styles/footer.css";
@import "./styles/kpi.css";
@import "./styles/aboutTowers.css";
@import "./styles/infrastructure.css";
@import "./styles/othersPage.css";
@import "./styles/industries.css";
@import "./styles/helpfullLinks.css";

@font-face {
  font-family: "GilroyLight";
  src: local("GilroyLight"), url("./fonts/Gilroy-Light.otf") format("truetype");
}

@font-face {
  font-family: "GilroyRegular";
  src: local("GilroyRegular"),
    url("./fonts/Gilroy-Regular.ttf") format("truetype");
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "GilroyRegular";
}

a:hover {
  color: #f49807;
}

.spin {
  animation-name: spinning;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spinning {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.swiper-pagination-bullet {
  position: relative;
  height: 14px !important;
  width: 14px !important;
  display: inline-block !important;
  background-color: #fff !important;

  opacity: 1 !important;
}

.swiper-pagination-bullet::after {
  content: "";
  border: 1px solid #d4d4d4;
  height: 14px;
  width: 14px;
  position: absolute;
  padding: 8px;
  transform: translate(-50%, -12%);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  -webkit-transform: translate(-50%, -12%);
  -moz-transform: translate(-50%, -12%);
  -ms-transform: translate(-50%, -12%);
  -o-transform: translate(-50%, -12%);
}

.swiper-pagination-bullet-active {
  background-color: #f5b650 !important;
}

[data-reach-dialog-overlay] {
  background: hsla(0, 0%, 0%, 0.33);
  z-index: 1000000;
}

.map-company-icon-height {
  height: 3rem !important;
  width: 3rem !important;
}

/* .map-company-icon-height {
  margin-top: -2rem;
  margin-bottom: -4rem;
} */

.visit-button {
  font-weight: 500;
  display: inline-block;
}

.arrow-icon {
  display: inline-block;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}

.visit-button:hover .arrow-icon {
  transform: translateX(7px);
  -webkit-transform: translateX(7px);
  -moz-transform: translateX(7px);
  -ms-transform: translateX(7px);
  -o-transform: translateX(7px);
}

.rakib {
  /* background-image: linear-gradient(to bottom right, #f6921e, #f4bd21); */
  background-color: #000;
  /* background-color: gray; */
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.rakib a {
  text-decoration: none;
  background-color: #f6921e;
  color: #fff;
}

.rakib a:hover {
  background-color: #fff;
  color: #000;
}
