.btn-card {
  text-decoration: none;
  color: #f49807;
  border-bottom: 0.2rem solid #f49807;
}

.btn-card:hover {
  color: #f49807;
}

.blog-img-text {
  position: absolute;
  top: 0%;
  padding: 1rem 4rem;
}

.blog-img {
  width: 100%;
  height: auto;
}

.blog-img-position {
  top: -58%;
  left: 42%;
  z-index: -1;
}

.service-card-shadow {
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
}

.blog-card {
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
}

.blog-card span {
  display: block !important;
}

.blog-card-padding {
  padding: 5rem;
}

.blog-card-body-padding {
  padding: 2rem;
}

@media (max-width: 576px) {
  .service-card-shadow {
    box-shadow: none;
  }
  .blog-img-text {
    position: absolute;
    top: 0%;
    padding: 1rem 4rem;
  }

  .blog-img {
    width: 31rem;
  }

  .blog-img-position {
    top: -100%;
    left: 7.5%;
  }

  .blog-card-padding {
    padding: 0.5rem;
    margin-bottom: 2rem;
  }

  .blog-card-body-padding {
    padding: 2rem;
  }
}

@media (min-width: 577px) and (max-width: 767px) {
}

@media (min-width: 768px) and (max-width: 991px) {
  .blog-img-position {
    left: 32%;
    top: -70%;
  }

  .blog-img {
    width: 85%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .blog-img {
    width: 75%;
  }

  .blog-img-position {
    top: -78%;
    left: 36.5%;
  }

  .blog-card-padding {
    padding: 0.5rem;
  }

  .blog-card-body-padding {
    padding: 1.5rem;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .blog-img-position {
    top: -83%;
    left: 39%;
  }

  .blog-card-padding {
    padding: 2rem;
  }
}

@media (min-width: 1400px) and (max-width: 1799px) {
  .blog-img-position {
    top: -69%;
    left: 40.5%;
  }

  .blog-card-padding {
    padding: 1.5rem;
  }
}
