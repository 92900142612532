.team-img-position {
  top: -68%;
  z-index: -1;
}

.team-img {
  width: 100%;
  height: auto;
}

.team-card-img {
  width: 31.3rem;
}

.team-card-header {
  width: 31rem;
  height: 31.7rem;
}

.team-card-social {
  width: 31rem;
  height: 31.7rem;
  position: absolute;
  top: 0%;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  background-color: rgba(19, 19, 19, 0.78);
}

.team-card-header:hover .team-card-social {
  transition: all 0.5s ease-in-out;
  opacity: 1;
}

.team-card-body {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  padding: 1.8rem 13.4rem 0.5rem 3.4rem;
  width: 31rem;
}

.team-card-btn:hover {
  color: #f49807;
}

.team-filter-container {
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
  padding: 5rem 10rem;
  margin-top: 6rem;
}

.team-filter-container-active {
  border: 0.1rem solid #f49807;
  padding: 0.5rem 1rem;
}

@media (max-width: 576px) {
  .team-img-position {
    top: -79%;
  }

  .team-card-header {
    width: 31rem;
    height: 31.7rem;
  }

  .team-card-img {
    width: 100%;
    height: auto;
  }

  .team-card-social {
    width: 31rem;
    height: 31.5rem;
  }

  .team-card-body {
    width: 30.5rem;
    padding: 1.8rem 8rem 0.5rem 3.4rem;
  }

  .team-filter-container {
    padding: 1rem 2rem;
    margin-top: 1rem;
    width: 91%;
  }

  .team-mobile-position {
    position: relative;
    left: 2.4rem;
  }
}

@media (min-width: 577px) and (max-width: 767px) {
}

@media (min-width: 768px) and (max-width: 991px) {
  .team-filter-container {
    padding: 1rem 1rem;
  }

  .team-img-position {
    top: -81%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .team-img-position {
    top: -73%;
  }

  .team-img {
    width: 70%;
  }

  .team-card-header {
    width: auto;
    height: 20.3rem;
  }

  .team-card-img {
    width: 20rem;
  }

  .team-card-social {
    width: 20rem;
    height: 20.3rem;
    top: 0%;
  }

  .team-card-body {
    padding: 1.8rem 1rem 0.5rem 1rem;
    width: 19.8rem;
  }

  .team-filter-container {
    padding: 3rem 3rem;
    margin-top: 5rem;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .team-img {
    width: 75%;
  }

  .team-img-position {
    top: -59%;
  }

  .team-card-img {
    width: 27.3rem;
  }

  .team-card-header {
    width: 27.3rem;
    height: 27.7rem;
  }

  .team-card-social {
    width: 27rem;
    height: 27.7rem;
    top: 0%;
  }

  .team-card-body {
    padding: 1.8rem 1.4rem 0.5rem 2.4rem;
    width: 26.8rem;
  }

  .team-filter-container {
    padding: 5rem 6rem;
    margin-top: 5rem;
  }
}

@media (min-width: 1400px) and (max-width: 1799px) {
  .team-img-position {
    top: -64%;
  }

  .team-card-img {
    width: 28rem;
  }

  .team-img {
    width: 85%;
  }

  .team-card-header {
    width: 31rem;
    height: 28.7rem;
  }

  .team-card-social {
    width: 27.7rem;
    height: 28.7rem;
  }

  .team-card-body {
    padding: 2.2rem 0rem 0.5rem 2rem;
    width: 27.7rem;
  }

  .team-filter-container {
    padding: 4rem 6rem;
  }
}
