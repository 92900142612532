.solution-h1 {
  border-bottom: 0.5rem solid #f49807;
  width: 17rem;
  margin-top: 5rem;
}

.solution p {
  width: 36rem;
  padding: 1.4rem 11.5rem 1.4rem 2rem;
  border-bottom: 0.2rem solid orange;
  background-color: #f9f9f9;
  margin-bottom: 5rem;
}

.solution-img {
  width: 100%;
  height: auto;
}

.solution-img-position {
  top: -11%;
  z-index: -1;
}

.solution-heading {
  width: 65%;
}

.solution-description {
  width: 80%;
}

@media (max-width: 576px) {
  .solution-img {
    width: 80%;
  }

  .solution-img-position {
    top: -8%;
  }

  .solution-heading {
    width: 100%;
  }
  
  .solution-description {
    width: 100%;
  }

  .solution-h1 {
    margin-top: 1rem;
  }

  .solution p {
    width: 100%;
    margin-bottom: 2rem;
  }
}

@media (min-width: 577px) and (max-width: 767px) {
  .solution p {
    width: 100%;
    margin-bottom: 2rem;
  }

  .solution-img {
    width: 100%;
  }

  .solution-img-position {
    top: -12%;
  }

  .solution-heading {
    width: 80%;
  }
  
  .solution-description {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .solution {
    margin-top: 5rem;
  }

  .solution p {
    width: 32rem;
    padding: 1.4rem 1.5rem 1.4rem 2rem;
  }

  .solution-img {
    width: 95%;
  }

  .solution-img-position {
    top: -12%;
  }

  .solution-heading {
    width: 60%;
  }
  
  .solution-description {
    width: 100%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .solution-img {
    width: 95%;
  }

  .solution-img-position {
    top: -12%;
  }

  .solution-heading {
    width: 85%;
  }
  
  .solution-description {
    width: 100%;
  }

  .solution-h1 {
    margin-top: 3rem;
  }

  .solution p {
    width: 21rem;
    padding: 1rem 0.5rem 1rem 1rem;
    margin-bottom: 2rem;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .solution-img {
    width: 95%;
  }

  .solution-img-position {
    top: -12%;
  }

  .solution-heading {
    width: 75%;
  }
  
  .solution-description {
    width: 95%;
  }

  .solution p {
    width: 26rem;
    padding: 1.4rem 0.5rem 1.4rem 1rem;
    margin-bottom: 2rem;
  }
}

@media (min-width: 1400px) and (max-width: 1799px) {
  .solution-h1 {
    border-bottom: 0.5rem solid #f49807;
    width: 17rem;
    margin-top: 5rem;
  }

  .solution p {
    width: 28rem;
    padding: 1.4rem 1.5rem 1.4rem 2rem;
    border-bottom: 0.2rem solid orange;
    background-color: #f9f9f9;
    margin-bottom: 2rem;
  }

  .solution-img {
    width: 100%;
  }

  .solution-img-position {
    top: -12%;
  }

  .solution-heading {
    width: 67%;
  }
  
  .solution-description {
    width: 90%;
  }
}
