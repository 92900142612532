.rounded-lg {
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
}

.review-shadow {
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.1) !important;
}

.review-image-position {
  top: -50px;
  right: 35%;
}

.review-card-border {
  height: 11px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(270deg, #ffdd00, #fbb034);
  border-radius: 0 0 10px 10px;
  -webkit-border-radius: 0 0 10px 10px;
  -moz-border-radius: 0 0 10px 10px;
  -ms-border-radius: 0 0 10px 10px;
  -o-border-radius: 0 0 10px 10px;
}

.review-card {
  padding: 1rem;
  margin: 9rem 0rem 12rem 0rem;
  background-color: #ffffff;
  border-radius: 4rem;
  -webkit-border-radius: 4rem;
  -moz-border-radius: 4rem;
  -ms-border-radius: 4rem;
  -o-border-radius: 4rem;
}

.review-img-position {
  top: -23%;
}

.review-img {
  width: 60rem;
}

.review-card-content {
  padding: 5rem 4rem 0rem 4rem;
}

.review-h1 {
  position: relative;
  z-index: 1;
}

@media (max-width: 576px) {
  .review-img {
    width: 28rem;
  }

  .review-img-position {
    top: -42%;
  }

  .profile-img {
    width: 11rem;
  }

  .review-card {
    padding: 1rem;
    margin: 3rem 0rem 6rem 0rem;
    border-radius: 1rem;
  }

  .review-card-content {
    padding: 0rem 1rem 0rem 0rem;
  }

  .review-card-content .fs-22 {
    font-size: 1.6rem !important;
  }

  .review-card-content .fs-28 {
    font-size: 2rem !important;
  }
}

@media (min-width: 577px) and (max-width: 767px) {
}

@media (min-width: 768px) and (max-width: 991px) {
  .review-img {
    width: 44rem;
  }

  .review-img-position {
    top: -27%;
  }

  .profile-img {
    width: 18rem;
  }

  .review-card {
    padding: 1.5rem;
    margin: 9rem 0rem 12rem 0rem;
    border-radius: 2rem;
  }

  .quote-position {
    top: -9%;
    left: 0%;
  }

  .quote {
    width: 4rem;
  }

  .review-card-content {
    padding: 3rem 3rem 1rem 2rem;
  }

  .review-card-content .fs-22 {
    font-size: 1.6rem !important;
  }

  .review-card-content .fs-28 {
    font-size: 2rem !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .review-img {
    width: 44rem;
  }

  .review-img-position {
    top: -41%;
  }

  .profile-img {
    width: 11rem;
  }

  .review-card {
    padding: 1.5rem;
    margin: 9rem 0rem 12rem 0rem;
  }

  .quote-position {
    top: -9%;
    left: 0%;
  }

  .quote {
    width: 3rem;
  }

  .review-card-content {
    padding: 2rem 1rem 0rem 1rem;
  }

  .review-card-content .fs-22 {
    font-size: 1.6rem !important;
  }

  .review-card-content .fs-28 {
    font-size: 2rem !important;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .review-img-position {
    top: -26%;
  }

  .profile-img {
    width: 20rem;
  }

  .review-card {
    padding: 2rem;
    margin: 9rem 0rem 12rem 0rem;
  }

  .quote-position {
    top: -9%;
    left: 0%;
  }

  .quote {
    width: 3.5rem;
  }

  .review-card-content {
    padding: 2rem 2rem 0rem 2rem;
  }

  .review-card-content .fs-22 {
    font-size: 1.8rem !important;
  }

  .review-card-content .fs-28 {
    font-size: 2.2rem !important;
  }
}

@media (min-width: 1400px) and (max-width: 1680px) {
  .review-img-position {
    top: -24%;
  }

  .profile-img {
    width: 20rem;
  }

  .review-card {
    padding: 2rem;
    margin: 9rem 0rem 12rem 0rem;
  }

  .quote-position {
    top: -9%;
    left: 0%;
  }

  .quote {
    width: 3.5rem;
  }

  .review-card-content {
    padding: 2rem 2rem 0rem 2rem;
  }

  .review-card-content .fs-22 {
    font-size: 1.8rem !important;
  }

  .review-card-content .fs-28 {
    font-size: 2.2rem !important;
  }
}
