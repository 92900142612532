/* .award-card-body {
  width: 37rem;
  height: 40.2rem;
  position: absolute;
  top: 0%;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  background-color: rgba(19, 19, 19, 0.78);
  text-align: center;
}

.award-card-header {
  width: 37rem;
  height: 40.2rem;
}

.award-card-header:hover .award-card-body {
  opacity: 1;
}

.award-card-img {
  width: 37rem;
  height: 40.2rem;
}

.award-img-position {
  top: -56%;
  z-index: -1;
}

.award-img {
  width: 36rem;
}

.check-image {
  display: block;
  width: 100%;
  padding: 1rem;
}

.check-div {
  columns: 3;
  column-gap: 0;
}

@media (max-width: 576px) {
  .award-img {
    width: 32rem;
  }

  .award-img-position {
    top: -42%;
  }

  .award-card-body {
    width: 34rem;
    height: 36.6rem;
  }

  .award-card-header {
    width: 34rem;
    height: 40.2rem;
  }

  .award-card-img {
    width: 34rem;
    height: auto;
  }

  .award-mobile-position {
    margin-left: 0.8rem;
  }

  .check-div {
    columns: 1;
  }
}

@media (min-width: 577px) and (max-width: 767px) {
}

@media (min-width: 768px) and (max-width: 991px) {
  .award-card-body {
    width: 33rem;
    top: 5%;
    height: 35.6rem;
  }

  .award-card-img {
    width: 33rem;
  }

  .check-div {
    columns: 2;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .award-card-body {
    width: 27.8rem;
    height: 30.2rem;
  }

  .award-card-header {
    width: 28rem;
    height: 30.2rem;
  }

  .award-card-img {
    width: 28rem;
    height: 30.2rem;
  }

  .award-img-position {
    top: -109%;
  }

  .award-img {
    width: 36rem;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .award-img {
    width: 37.9rem;
  }

  .award-img-position {
    top: -73%;
  }
}

@media (min-width: 1400px) and (max-width: 1799px) {
  .award-card-body {
    width: 27.8rem;
    height: 30.2rem;
  }

  .award-card-header {
    width: 100%;
    height: 31rem;
  }

  .award-card-img {
    width: 28rem;
    height: 30.2rem;
  }

  .award-img-position {
    top: -83%;
  }

  .award-img {
    width: 36rem;
  }
} */

.carousel-root {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.carousel-root .carousel-slider {
  width: 70% !important;
}

.carousel .slide .legend {
  opacity: 1 !important;
  background-color: rgb(0, 0, 0, 0.75) !important;
}

@media (max-width: 992px) {
  .carousel-root .carousel-slider {
    width: 100% !important;
  }
}
