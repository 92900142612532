.logo {
  height: 4.2rem;
  width: 17rem;
}

.nav-link {
  border-bottom: 0.5rem solid transparent;
}

.nav-link:hover {
  border-bottom: 0.5rem solid orange;
  transition: border-bottom 0.5s ease-in-out;
}

.navbar-active {
  border-bottom: 0.5rem solid orange;
}

.navbar-mx-5 {
  margin: 0rem 0rem 0rem 5rem;
}

.dropdown-item.active {
  background-color: transparent !important;
  border-bottom: 0.5rem solid orange;
}

.dropdown-item:hover {
  background-color: orange;
}

.dropdown-toggle::after {
  margin-left: 1em;
}

/* .accordion-button::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='240' height='240'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
  height: 2.2rem;
  width: 2.2rem;
  background-size: cover;
} */

/* .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='240' height='240'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
} */

@media (max-width: 576px) {
  .logo {
    height: 3rem;
    width: 8rem;
  }

  .navbar-mx-5 {
    margin: 0rem 0rem 0rem 0rem;
  }

  nav {
    background-color: #212529;
  }
}

@media (min-width: 577px) and (max-width: 767px) {
  .navbar-mx-5 {
    margin: 0rem 0rem 0rem 0rem;
  }

  nav {
    background-color: #212529;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .logo {
    height: 4.2rem;
    width: 17rem;
  }

  nav {
    background-color: #212529;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .logo {
    height: 4rem;
    width: 10rem;
  }

  .navbar-mx-5 {
    margin: 0rem 0rem 0rem 3rem;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .navbar-mx-5 {
    margin: 0rem 0rem 0rem 3rem;
  }
}

@media (min-width: 1400px) {
}

.submenu-link {
  background-color: #f49807;
}

.submenu-link a {
  color: #fff;
}

.submenu-link:hover a {
  color: #f49807;
}

.submenu-link:hover {
  background-color: #ececec;
}
