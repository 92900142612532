.customer-img-position {
  top: -8%;
  z-index: 0;
}

@media (max-width: 576px) {
  .customer-img-position {
    top: -14%;
  }
}

@media (min-width: 577px) and (max-width: 767px) {
}

@media (min-width: 768px) and (max-width: 991px) {
  .customer-img-position {
    top: -15%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    left: 0;
    right: 0;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .customer-img-position {
    top: -14%;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .customer-img-position {
    top: -12%;
  }
}

@media (min-width: 1400px) and (max-width: 1680px) {
  .customer-img-position {
    top: -11%;
  }
}
