.news-letter-input {
  border: none;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  width: 50rem;
  padding: 2.3rem 2rem;
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
}

.news-letter-input:focus {
  outline: 1px solid #f49807;
}

.news-letter-btn {
  padding: 1.9rem 7rem;
  border-bottom-right-radius: 1rem;
  border-top-right-radius: 1rem;
  border: none;
  margin-left: -1rem;
}

@media (max-width: 576px) {
  .news-letter-input {
    width: 70%;
    padding: 1rem 4rem 1rem 2rem;
    margin-left: 0rem;
    border-radius: 1rem;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -ms-border-radius: 1rem;
    -o-border-radius: 1rem;
  }

  .news-letter-btn {
    width: 30%;
    padding: 1.15rem 2rem;
    border-radius: 1rem;
    margin-right: 0rem;
    margin-top: 0rem;
    font-size: 1.4rem !important;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -ms-border-radius: 1rem;
    -o-border-radius: 1rem;
  }
}

@media (min-width: 577px) and (max-width: 767px) {
  .news-letter-input {
    width: 70%;
    padding: 1rem 4rem 1rem 2rem;
    margin-left: 0rem;
    border-radius: 1rem;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -ms-border-radius: 1rem;
    -o-border-radius: 1rem;
  }

  .news-letter-btn {
    width: 30%;
    padding: 1.15rem 2rem;
    border-radius: 1rem;
    margin-right: 0rem;
    margin-top: 0rem;
    font-size: 1.4rem !important;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -ms-border-radius: 1rem;
    -o-border-radius: 1rem;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .news-letter-input {
    width: 70%;
    padding: 1rem 4rem 1rem 2rem;
    margin-left: 0rem;
    border-radius: 1rem;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -ms-border-radius: 1rem;
    -o-border-radius: 1rem;
  }

  .news-letter-btn {
    width: 30%;
    padding: 1.15rem 2rem;
    border-radius: 1rem;
    margin-right: 0rem;
    margin-top: 0rem;
    font-size: 1.4rem !important;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -ms-border-radius: 1rem;
    -o-border-radius: 1rem;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .news-letter-input {
    width: 50rem;
    padding: 1.9rem 2rem;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
}

@media (min-width: 1400px) {
}
