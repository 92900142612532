.bulb-position {
  top: 35%;
  right: 42%;
}

.bulb-background {
  padding: 2rem 2.2rem;
  margin-right: 2rem;
  background-color: #eeb600;
}

.academy-card {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: #ffffff;
}

.academy-card p {
  margin-top: 1rem;
  padding-right: 1rem;
}

.academy-img {
  width: 48.5rem;
}

.academy-img-position {
  top: -66%;
}

.academy-video iframe {
  width: 100%;
  height: 50rem;
}

@media (max-width: 576px) {
  .playImg-position {
    top: 37%;
    left: 41%;
  }

  .play-img {
    max-width: 50%;
  }

  .academy-content {
    left: 0rem;
    width: 100%;
  }

  .bulb-text {
    position: relative;
    left: 0rem;
  }

  .academy-img {
    width: 32.5rem;
  }

  .academy-img-position {
    top: -62%;
    left: 2%;
  }

  .academy-video iframe {
    height: 25rem;
  }
}

@media (min-width: 577px) and (max-width: 767px) {
}

@media (min-width: 768px) and (max-width: 991px) {
  .academy-img {
    width: 48.5rem;
  }

  .academy-img-position {
    left: 16%;
  }

  .academy-content {
    /* left: 16%; */
    width: 100%;
    position: relative;
    margin-bottom: 5rem;
  }

  .academy-video iframe {
    height: 36rem;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .academy-img {
    width: 35rem;
  }

  .academy-img-position {
    top: -61%;
    left: 29%;
  }

  .play-img {
    max-width: 55%;
    height: auto;
  }

  .academy-video iframe {
    height: 30.5rem;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .academy-img {
    width: 37.9rem;
  }

  .academy-img-position {
    top: -50%;
    left: 33.5%;
  }

  .play-img {
    max-width: 74%;
    height: auto;
  }

  .academy-video iframe {
    height: 35rem;
  }
}

@media (min-width: 1400px) and (max-width: 1699px) {
  .play-img {
    max-width: 70%;
    height: auto;
  }

  .academy-img-position {
    top: -79%;
    left: 30%;
  }

  .academy-video iframe {
    height: 40rem;
  }
}
