.btn-design {
  border: none;
  color: #000000;
  background-color: #eeb600;
  border-radius: 1rem;
  box-shadow: inset 0 0 0 0 #343f49;
  transition: ease-in-out 0.9s;
  outline: none;
  padding: 1rem 3rem;
}

.btn-design:hover {
  box-shadow: inset 20rem 0 0 0 #343f49;
  color: #ffffff;
  cursor: pointer;
}

@media (max-width: 576px) {
  .btn-design {
    padding: 1rem 2rem;
  }
}
