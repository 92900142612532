.footprint-img-position {
  z-index: -1;
  top: -6%;
}

.css-1s2u09g-control {
  border-color: #f49807 !important;
  border-width: 0.5rem !important;
  height: 6rem;
}

.css-1pahdxg-control {
  border-color: #f49807 !important;
  border-width: 0.5rem !important;
  height: 6rem;
}

@media (max-width: 576px) { 
  .footprint-img-position {
    top: 0%;
  }
}

@media (min-width: 768px) and (max-width: 991px) { 
  .footprint-img-position {
    top: -4%;
  }
}
