@font-face {
  font-family: "GilroyBold";
  src: local("GilroyBold"),
    url("../fonts/Gilroy-ExtraBold.otf") format("truetype");
}

@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@700&display=swap");

.plex-sans {
  font-family: "IBM Plex Sans", sans-serif;
}

.gilroy-bold {
  font-family: "GilroyBold";
}

.fs-12 {
  font-size: 1.2rem;
}

.fs-14 {
  font-size: 1.4rem;
}

.fs-16 {
  font-size: 1.6rem;
}

.fs-18 {
  font-size: 1.8rem;
}

.fs-20 {
  font-size: 2rem;
}

.fs-22 {
  font-size: 2.2rem;
}

.fs-24 {
  font-size: 2.4rem;
}

.fs-26 {
  font-size: 2.6rem;
}

.fs-28 {
  font-size: 2.8rem;
}

.fs-30 {
  font-size: 3rem;
}

.fs-32 {
  font-size: 3.2rem;
}

.fs-34 {
  font-size: 3.4rem;
}

.fs-38 {
  font-size: 3.8rem;
}

.fs-42 {
  font-size: 4.2rem;
}

.fs-56 {
  font-size: 5.6rem;
}

.fs-60 {
  font-size: 6rem;
}

.fs-64 {
  font-size: 6.4rem;
}

.fs-100 {
  font-size: 10rem;
}

.fw-700 {
  font-weight: 700;
}

.fw-900 {
  font-weight: 900;
}

.lh-1 {
  line-height: 0.2rem !important;
}

.lh-26 {
  line-height: 2.4rem;
}

.lh-28 {
  line-height: 2.8rem;
}

.lh-30 {
  line-height: 3rem;
}

.lh-32 {
  line-height: 3.2rem;
}

.lh-36 {
  line-height: 3.6rem;
}

.lh-40 {
  line-height: 4rem;
}

.lh-64 {
  line-height: 6.4rem;
}

@media (max-width: 576px) {
  .fs-16 {
    font-size: 1.6rem;
  }

  .fs-18 {
    font-size: 1.6rem;
  }

  .fs-20 {
    font-size: 1.6rem;
  }

  .fs-24 {
    font-size: 1.6rem;
  }

  .fs-26 {
    font-size: 2rem;
  }

  .fs-28 {
    font-size: 2rem;
  }

  .fs-38 {
    font-size: 2.8rem;
  }

  .fs-56 {
    font-size: 3.4rem;
  }

  .fs-60 {
    font-size: 3.2rem;
  }

  .fs-64 {
    font-size: 4rem;
  }

  .fs-100 {
    font-size: 4rem;
  }

  .lh-40 {
    line-height: 3rem;
  }

  .lh-64 {
    line-height: 4.2rem;
  }
}

@media (min-width: 577px) and (max-width: 767px) {
}

@media (min-width: 768px) and (max-width: 991px) {
  .fs-26 {
    font-size: 2.2rem;
  }

  .fs-56 {
    font-size: 4.6rem;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .fs-18 {
    font-size: 1.6rem;
  }

  .fs-20 {
    font-size: 1.6rem;
  }

  .fs-24 {
    font-size: 2rem;
  }

  .fs-26 {
    font-size: 2rem;
  }

  .fs-28 {
    font-size: 2rem;
  }

  .fs-56 {
    font-size: 3.8rem;
  }

  .fs-64 {
    font-size: 4.4rem;
  }

  .lh-64 {
    line-height: 4.4rem;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .fs-20 {
    font-size: 1.8rem;
  }

  .fs-28 {
    font-size: 2.4rem;
  }

  .fs-56 {
    font-size: 4.6rem;
  }
}

@media (min-width: 1400px) and (max-width: 1680px) {
  .fs-28 {
    font-size: 2.6rem;
  }

  .fs-56 {
    font-size: 4.9rem;
  }
}

/* 
@media (min-width: 577px) and (max-width: 767px) {
}

@media (min-width: 768px) and (max-width: 991px) {
}

@media (min-width: 992px) and (max-width: 1199px) {
}

@media (min-width: 1200px) and (max-width: 1399px) {
}

@media (min-width: 1400px) {
}
 */
