.service-img-position {
  top: -47%;
  z-index: -1;
}

.service-img {
  width: 100%;
  height: auto;
}

.what-we-do-img {
  border-radius: 4rem;
  width: 100%;
  height: 52rem;
  object-fit: cover;
}

.what-we-do-overlay {
  position: absolute;
  inset: 0% 0% 0% 0%;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 4rem;
}

.what-we-do-content {
  position: absolute;
  inset: 5% 5% 5% 5%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.what-we-do-btn {
  display: inline-block;
  background-color: rgb(236 236 236 / 30%);
  padding: 1rem 2rem;
  border-radius: 5rem;
}

.what-we-card-img {
  width: 100%;
  height: 20rem;
}

.what-we-swiper {
  z-index: 0 !important;
}

[data-reach-dialog-content-overlay] {
  position: relative;
  z-index: 100000000 !important;
}

@media (max-width: 576px) {
  .service-img-position {
    top: -40%;
  }

  .service-img {
    width: 90%;
  }

  .what-we-do-img {
    height: 48rem;
  }

  [data-reach-dialog-content] {
    width: 95vw !important;
  }
}

@media (min-width: 577px) and (max-width: 767px) {
  [data-reach-dialog-content] {
    width: 90vw !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .service-img-position {
    top: -52%;
  }

  .service-img {
    width: 90%;
  }

  .what-we-do-img {
    height: 48rem;
  }

  [data-reach-dialog-content] {
    width: 90vw !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .service-img-position {
    top: -82%;
  }

  .service-img {
    width: 90%;
  }

  .what-we-do-img {
    height: 42rem;
  }
  [data-reach-dialog-content] {
    width: 80vw !important;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .service-img-position {
    top: -54%;
  }

  .service-img {
    width: 90%;
  }

  .what-we-do-img {
    height: 38rem;
  }
  /* [data-reach-dialog-content] {
    width: 70vw !important;
  } */
}

@media (min-width: 1400px) and (max-width: 1680px) {
  .service-img-position {
    top: -47%;
  }

  .what-we-do-img {
    height: 42rem;
  }
}
