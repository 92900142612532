.cursor-pointer {
  cursor: pointer;
}

.software-apps-svg-icons {
  fill: red;
}

.object-fit-none {
  object-fit: none;
}

.text-align-justify {
  text-align: justify;
}

.accordion-box-shadow {
  box-shadow: 0 0 5px 0 rgb(0 0 0 / 10%);
}

.text-charleston-green {
  color: #282828;
}

.text-spanish-gray {
  color: #989899;
}

.text-orange-ryb {
  color: #f49807;
}

.text-american-yellow {
  color: #f49807;
}

.bg-ghost-white {
  background-color: #f9f9f9;
}

.bg-bright-gray {
  background-color: #efefef;
}

.bg-chinese-black {
  background-color: #212529;
}

.text-chinese-black {
  color: #212529;
}

.bg-american-yellow {
  background-color: #f49807;
}

.bg-american-yellow-light {
  background-color: #f5b650;
}

.bg-bright-gray {
  background-color: #ececec;
}

.bg-water {
  background-color: #d8f4f1;
}

.bg-blanched-almond {
  background-color: #fff2cc;
}

.bg-azureish-white {
  background-color: #dae3f3;
}

.bg-maximum-blue-green {
  background-color: #34c2b4;
}

.bg-ultramarine-blue {
  background-color: #536eef;
}

.cut-line {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.cut-line-1 {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.text-shadow {
  text-shadow: 0.2rem 0.2rem black;
}

.border-yellow {
  border: 0.2rem solid #f49807;
}

.border-gray {
  border: 1px solid gray;
}

.border-right-yellow {
  border-right: 3px solid #f49807;
}

.list-style-none {
  list-style-type: none;
}

.side-menu:hover {
  background-color: #f49807;
}

.side-menu:hover a {
  color: #fff !important;
}

.w-35 {
  width: 40rem;
}

.ls-1 {
  letter-spacing: 1rem;
}

.ls-0 {
  letter-spacing: 0.86px;
}

.glass-effect {
  background: linear-gradient(135deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  backdrop-filter: blur(0.5rem);
  box-shadow: 0 0.8rem 3.2rem 0 rgba(0, 0, 0, 0.5);
  border: 0.1rem solig rgba(255, 255, 255, 0.18);
  border-radius: 2rem;
}

.px-8 {
  padding: 0 8rem;
}

.pt-7 {
  padding-top: 7rem;
}

.pt-11 {
  padding-top: 11rem;
}

.pt-13 {
  padding-top: 13rem;
}

.pb-7 {
  padding-bottom: 7rem;
}

.pb-9 {
  padding-bottom: 9rem;
}

.pb-13 {
  padding-bottom: 13rem;
}

.ms-7 {
  margin-left: 7rem;
}

.ms-13 {
  margin-left: 13rem;
}

.me-13 {
  margin-right: 13rem;
}

.mx-7 {
  margin: 0 7rem;
}

.ms-35 {
  margin-left: 35rem;
}

.me-35 {
  margin-right: 35rem;
}

.ms-47 {
  margin-left: 47rem;
}

.me-47 {
  margin-right: 47rem;
}

.mt-5 {
  margin-top: 5rem !important;
}

.mt-7 {
  margin-top: 7rem;
}

.mt-9 {
  margin-top: 9rem;
}

.mt-12 {
  margin-top: 12rem;
}

.mt-13 {
  margin-top: 13rem;
}

.mt-17 {
  margin-top: 17rem;
}

.mt-21 {
  margin-top: 21rem;
}

.remote-monitoring-clip-path {
  clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
}

/* font testing */
body::-webkit-scrollbar {
  width: 1rem;
}
body::-webkit-scrollbar-track {
  background-color: #e7e7e7;
  border-radius: 100vh;
}
body::-webkit-scrollbar-thumb {
  background-image: linear-gradient(73deg, #f49807, #f49807);
  border-radius: 100vh;
}

nav {
  position: fixed !important;
  top: 15;
  left: 0;
  width: 100%;
  z-index: 9;
  transition: all 300ms ease-in-out;
}

.scroll-down nav {
  top: 0;
  transform: translate3d(0, -100%, 0);
}
.scroll-up nav {
  top: 0;
  background-color: #212529;
}

.software-and-apps-img {
  height: 48px;
  width: 48px;
}

.software-apps-svg-icons {
  height: 8rem;
  width: 8rem;
}

.service-image-banner {
  height: 40rem;
  object-fit: cover;
  object-position: 50% 50%;
}

/*  mobile */
@media (max-width: 576px) {
  .service-image-banner {
    height: 20rem;
  }
  .mt-5 {
    margin-top: 3rem !important;
  }

  .mx-7 {
    margin: 0 0rem;
  }

  .ms-7 {
    margin-left: 0rem;
  }

  .mt-7 {
    margin-top: 3rem;
  }

  .mt-12 {
    margin-top: 2rem;
  }

  .mt-13 {
    margin-top: 8rem;
  }

  .mt-9 {
    margin-top: 4rem;
  }

  .ms-13 {
    margin-left: 1rem;
  }

  .me-13 {
    margin-right: 1rem;
  }

  .px-8 {
    padding: 0 0rem;
  }

  .pt-11 {
    padding-top: 3rem;
  }

  .pt-13 {
    padding-top: 8rem;
  }

  .pb-9 {
    padding-bottom: 2rem;
  }

  .pb-13 {
    padding-bottom: 5rem;
  }

  .mt-17 {
    margin-top: 8rem;
  }

  .ms-47 {
    margin-left: 2rem;
  }

  .me-47 {
    margin-right: 2rem;
  }

  .ms-35 {
    margin-left: 1rem;
  }

  .me-35 {
    margin-right: 1rem;
  }

  .pt-7 {
    padding-top: 5rem;
  }

  .pb-7 {
    padding-bottom: 5rem;
  }

  .remote-monitoring-clip-path {
    clip-path: none;
  }

  .remote-monitoring-clip-path .w-75 {
    width: 100% !important;
  }
}

@media (min-width: 577px) and (max-width: 767px) {
  .mt-5 {
    margin-top: 3rem !important;
  }

  .mx-7 {
    margin: 0 0rem;
  }

  .ms-7 {
    margin-left: 0rem;
  }

  .mt-7 {
    margin-top: 3rem;
  }

  .mt-12 {
    margin-top: 2rem;
  }

  .mt-13 {
    margin-top: 8rem;
  }

  .mt-9 {
    margin-top: 4rem;
  }

  .ms-13 {
    margin-left: 1rem;
  }

  .me-13 {
    margin-right: 1rem;
  }

  .px-8 {
    padding: 0 1rem;
  }

  .pt-11 {
    padding-top: 3rem;
  }

  .pt-13 {
    padding-top: 8rem;
  }

  .pb-9 {
    padding-bottom: 2rem;
  }

  .pb-13 {
    padding-bottom: 5rem;
  }

  .mt-17 {
    margin-top: 8rem;
  }

  .ms-47 {
    margin-left: 2rem;
  }

  .me-47 {
    margin-right: 2rem;
  }

  .ms-35 {
    margin-left: 2rem;
  }

  .me-35 {
    margin-right: 2rem;
  }

  /* .remote-monitoring-clip-path {
    clip-path: none;
  } */
}

/* tablet */
@media (min-width: 768px) and (max-width: 991px) {
  .pt-13 {
    padding-top: 8rem;
  }

  .pb-13 {
    padding-bottom: 8rem;
  }

  .mt-13 {
    margin-top: 8rem;
  }

  .ms-13 {
    margin-left: 5rem;
  }

  .me-13 {
    margin-right: 5rem;
  }

  .mx-7 {
    margin: 0 5rem;
  }

  .mt-17 {
    margin-top: 14rem;
  }

  .ms-47 {
    margin-left: 2rem;
  }

  .me-47 {
    margin-right: 2rem;
  }

  .ms-35 {
    margin-left: 2rem;
  }

  .me-35 {
    margin-right: 2rem;
  }

  .px-8 {
    padding: 0 0rem;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .ms-13 {
    margin-left: 8rem;
  }

  .me-13 {
    margin-right: 8rem;
  }

  .mt-5 {
    margin-top: 4rem !important;
  }

  .mt-17 {
    margin-top: 14rem;
  }

  .pb-9 {
    padding-bottom: 6rem;
  }

  .ms-47 {
    margin-left: 6rem;
  }

  .me-47 {
    margin-right: 6rem;
  }

  .ms-35 {
    margin-left: 6rem;
  }

  .me-35 {
    margin-right: 6rem;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .ms-13 {
    margin-left: 8rem;
  }

  .me-13 {
    margin-right: 8rem;
  }

  .mx-7 {
    margin: 0 7rem;
  }

  .ms-47 {
    margin-left: 19rem;
  }

  .me-47 {
    margin-right: 19rem;
  }

  .ms-35 {
    margin-left: 15rem;
  }

  .me-35 {
    margin-right: 15rem;
  }
}

@media (min-width: 1400px) and (max-width: 1680px) {
  .display-none {
    display: none !important;
  }

  .ms-35 {
    margin-left: 25rem;
  }

  .me-35 {
    margin-right: 25rem;
  }

  .ms-47 {
    margin-left: 21rem;
  }

  .me-47 {
    margin-right: 21rem;
  }
}

.leaflet-div-icon {
  background: none !important;
  border: none !important;
}

.cluster-marker {
  color: #fff;
  background: #162a47;
  border: 4px solid #fff;
  border-radius: 50%;
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cluster-marker:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #162a47;
  opacity: 0.4;
  z-index: -1;
  border-radius: 50%;
  animation: pluse 2s ease-out 1s infinite;
  -webkit-animation: pluse 2s ease-out 1s infinite;
}

.leaflet-marker-icon {
  height: 50px !important;
  width: 50px !important;
}

@keyframes markerpluse {
  0% {
    z-index: 1;
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    opacity: 0.4;
  }
  50% {
    z-index: 1;
    transform: scale(1.4);
    -webkit-transform: scale(1.4);
    -moz-transform: scale(1.4);
    -ms-transform: scale(1.4);
    -o-transform: scale(1.4);
    opacity: 0.1;
  }
  100% {
    z-index: 1;
    transform: scale(1.8);
    -webkit-transform: scale(1.8);
    -moz-transform: scale(1.8);
    -ms-transform: scale(1.8);
    -o-transform: scale(1.8);
    opacity: 0;
  }
}

.transform-up {
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
}

.transform-up:hover {
  transform: translateY(-10px);
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  -o-transform: translateY(-10px);
}
