.approach-description {
  width: 85%;
}

.dot-image {
  position: absolute;
  bottom: -25px;
  left: -30px;
}

.animation-up-down {
  animation: up-down 4s linear infinite alternate;
  -webkit-animation: up-down 4s linear infinite alternate;
}

.approach-image {
  max-width: 400px;
  max-height: 460px;
  border-radius: 40px 4px 40px 4px;
  box-shadow: 0 30px 60px 0 rgb(1 11 60 / 14%);
}

.approach-img-position {
  z-index: -1;
  top: -53%;
}

@keyframes up-down {
  0% {
    transform: translateY(-100px);
    -webkit-transform: translateY(-100px);
    -moz-transform: translateY(-100px);
    -ms-transform: translateY(-100px);
    -o-transform: translateY(-100px);
  }
  100% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }
}

@media (max-width: 576px) {
  .approach-image {
    max-width: 250px;
    max-height: 270px;
  }

  .approach-description {
    width: 100%;
  }

  .approach-img-position {
    top: 30%;
  }
}

@media (min-width: 577px) and (max-width: 767px) {
}

@media (min-width: 768px) and (max-width: 991px) {
  .approach-description {
    width: 95%;
  }

  .approach-img-position {
    top: 15%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .approach-description {
    width: 100%;
  }

  .approach-img-position {
    top: -85%;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .approach-description {
    width: 95%;
  }

  .approach-img-position {
    top: -64%;
  }
}

@media (min-width: 1400px) and (max-width: 1680px) {
  .approach-description {
    width: 95%;
  }
}
