.header-swiper {
  height: 70rem !important;
  background: #212529;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.header-container {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}

.bg-video {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: 01;
  overflow: hidden;
}
.bg-video__content {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.linear-style {
  background-image: linear-gradient(
    109.6deg,
    rgb(36, 45, 57, 0.8) 11.2%,
    rgb(16, 37, 60, 0.8) 51.2%,
    rgb(0, 0, 0, 0.8) 98.6%
  );
  height: 100%;
  background-repeat: no-repeat;
}

.header-button {
  border: 0.1rem solid #f49807;
  background-color: transparent;
  padding: 0.9rem 4.6rem;
}

.header-description {
  width: 45%;
}

.header-background-image-size {
  padding: 10rem 0rem;
}

.header-social-icon {
  color: #ffffff;
  transition: all 0.5s ease-in-out;
}

.header-social-icon:hover {
  color: #f49807;
  transform: scale(1.2);
  transition: all 0.5s ease;
}

.afro-radio-logo {
  height: 20rem;
  width: 20rem;
  border-radius: 1rem;
}

.afro-radio-section {
  transition: all 0.6s ease-in-out;
  z-index: 1000;
  bottom: 6rem;
  left: 0rem;
}

.afro-radio-section-2 {
  width: 480px;
}

.afro-radio-section-3 {
  transition: transform 1s ease-in-out;
}

.afro-radio-section-3-inactive {
  transform: translateX(-150%);
}

.afro-radio-section-3-active {
  transform: translateX(78%);
}

.afro-radio-section-4 {
  right: -42px;
}

.radio-frame {
  height: 163px;
  width: 100%;
  border: 0;
  color: black;
}

@media (max-width: 576px) {
  .header-button {
    width: 100%;
    margin-bottom: 1rem;
  }

  .header-container {
    height: 100%;
  }

  .header-description {
    width: 100%;
  }

  .header-background-image-size {
    padding: 5rem 0rem;
  }

  .afro-radio-logo {
    height: 8rem;
    width: 8rem;
  }

  .afro-radio-section {
    bottom: 2rem;
    left: 2rem;
  }

  .afro-radio-section-3-active {
    transform: translateX(10%);
  }

  .radio-frame {
    height: 110px;
  }
}

@media (min-width: 577px) and (max-width: 767px) {
  .header-button {
    width: 100%;
    margin-bottom: 1rem;
  }

  .header-description {
    width: 100%;
  }

  .header-container {
    height: 100%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .header-description {
    width: 100%;
  }

  .afro-radio-logo {
    height: 12rem;
    width: 12rem;
  }

  .afro-radio-section {
    bottom: 3rem;
    left: 3rem;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .header-button {
    padding: 0.9rem 4rem;
  }

  .header-background-image-size {
    padding: 5rem 0rem;
  }

  .accordion-button::after {
    background-image: none !important;
  }

  .header-description {
    width: 65%;
  }

  .afro-radio-logo {
    height: 15rem;
    width: 15rem;
  }

  .afro-radio-section {
    bottom: 3rem;
    left: 0rem;
  }

  .afro-radio-section-3-active {
    transform: translateX(62%);
  }

  .radio-frame {
    height: 110px;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .header-description {
    width: 58%;
  }

  .afro-radio-logo {
    height: 15.5rem;
    width: 15.5rem;
  }

  .afro-radio-section {
    bottom: 4rem;
    left: 0rem;
  }

  .afro-radio-section-3-active {
    transform: translateX(64%);
  }

  .radio-frame {
    height: 120px;
  }
}

@media (min-width: 1400px) and (max-width: 1700px) {
  .header-description {
    width: 61%;
  }
}
