.infrastructure-img {
  width: 100%;
}

.where-we-work_mobile {
  display: none;
}

.where-we-work_tablet {
  display: none;
}

.where-we-work_desktop {
  display: block;
}

@media (max-width: 767px) {
  .where-we-work_mobile {
    display: block;
  }

  .where-we-work_tablet {
    display: none;
  }

  .where-we-work_desktop {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .where-we-work_mobile {
    display: none;
  }

  .where-we-work_tablet {
    display: block;
  }

  .where-we-work_desktop {
    display: none;
  }
}
