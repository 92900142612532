.contact-container {
  background-color: #f9f9f9;
  width: 80%;
  margin: auto;
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
  z-index: 1;
  position: relative;
}

.form-container {
  width: 80%;
  margin: auto;
  padding-top: 4rem;
}

.form-input {
  width: 100%;
  padding: 2rem 2rem;
  margin-top: 2rem;
  border: none;
  opacity: 0.7;
  border-radius: 0.5rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.form-select {
  width: 100%;
  padding: 3rem 2rem;
  margin: 2rem;
  border: none;
  opacity: 0.7;
  border-radius: 0.5rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  font-size: 1.6rem;
}

::placeholder {
  color: #a09e9e;
  opacity: 1;
}

.contact-btn {
  padding: 1.7rem 7rem;
  border: none;
}

.contact-img {
  width: 45.5rem;
  height: 12.8rem;
}

.contact-img-position {
  top: 33%;
  z-index: -1;
}

@media (max-width: 576px) {
  .contact-container {
    width: 95%;
  }

  .contact-margin-container {
    margin-top: 5rem;
  }

  .form-container {
    width: 94%;
    margin: 0rem 0rem 0rem -1rem;
    padding-top: 0rem;
  }

  .form-input {
    width: 100%;
    padding: 1rem 2rem;
    margin: 1rem 2rem 1rem 2rem;
  }

  .form-select {
    padding: 1rem 2rem;
    margin: 1rem 2rem 1rem 2rem;
  }

  .contact-btn {
    padding: 1rem 13rem;
    margin-left: 1rem;
    width: 100%;
  }

  .contact-img {
    width: 28.5rem;
    height: auto;
  }

  .contact-img-position {
    top: 42%;
  }
}

@media (min-width: 577px) and (max-width: 767px) {
  .contact-container {
    width: 100%;
  }

  .contact-margin-container {
    margin-top: 5rem;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .contact-container {
    width: 87%;
  }

  .contact-margin-container {
    margin-top: 5rem;
  }

  .contact-img-position {
    top: 5%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .contact-img {
    width: 31rem;
    height: auto;
  }

  .contact-img-position {
    top: 57%;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
}

@media (min-width: 1400px) and (max-width: 1799px) {
  .contact-img-position {
    top: 31%;
    left: 27%;
  }
}
