.career-card-img-1 {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.52),
      rgba(0, 0, 0, 0.73)
    ),
    url("../images/approach/our_value_and_approach.png");
  background-size: cover;
  height: 50rem;
}

.career-bg-image-1 {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.52),
      rgba(0, 0, 0, 0.73)
    ),
    url("../images/location-01-desktop.jpg");
  background-size: cover;
  height: 50rem;
}

/* Afro Academy Header Section */
.afro-academy-header-img {
  width: 100%;
}

.training-center-card-height {
  height: 60rem;
}

/* Service Section */
.afro-service-card-height {
  height: 30rem;
}

/* Build career */
.build-card-border {
  border: 0.1rem solid blue;
  border-bottom: 0.7rem solid blue;
  border-radius: 1rem;
  margin-bottom: 2rem;
}

/* Responsive */
@media (max-width: 576px) {
  /* Service Section */
  .afro-service-card-height {
    height: 100%;
  }

  .training-center-card-height {
    height: 100%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* Service Section */
  .afro-service-card-height {
    height: 22rem;
  }

  .training-center-card-height {
    height: 45rem;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  /* Service Section */
  .afro-service-card-height {
    height: 24rem;
  }

  .training-center-card-height {
    height: 45rem;
  }
}
