.social-icon {
  transition: all 0.5s ease-in-out;
}

.social-icon:hover {
  color: #f49807;
  transform: scale(1.2);
  transition: all 0.5s ease;
}

.footer-input {
  width: 28rem;
  padding: 1rem 1.5rem;
}

.footer-btn {
  padding: 1.2rem 4rem;
  border: none;
}

.footer-logo {
  width: 18.2rem;
}

.social-margin {
  margin: 0rem 4rem 0rem 0rem;
}

.footer-input-margin {
  margin: 0rem 0rem 0rem 1rem;
}

.footer-text {
  color: #fff;
}

.footer-text:hover {
  color: #f49807;
}

@media (max-width: 576px) {
  .footer-logo {
    width: 13.2rem;
    margin-left: 10rem;
  }

  .footer-input {
    font-size: 1.4rem !important;
    width: 100%;
    padding: 0.5rem 2rem 0.5rem 2rem;
    margin-left: -1rem;
  }

  .footer-btn {
    padding: 0.5rem 2.5rem 0.8rem 2.5rem;
    border: none;
    margin-top: 0.1rem;
    font-size: 1.4rem !important;
    margin-left: -1rem;
  }

  .footer-address-text {
    margin-left: 2.5rem;
  }
}

@media (min-width: 577px) and (max-width: 767px) {
}

@media (min-width: 768px) and (max-width: 991px) {
  .footer-logo {
    width: 25rem;
    margin-left: 20rem;
  }

  .footer-input {
    width: 57rem;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .social-margin {
    margin: 0rem 2rem 0rem 0rem;
  }

  .footer-input {
    font-size: 1.4rem !important;
    width: 17rem;
    padding: 0.3rem 0.5rem 0.2rem 0.5rem;
    margin-left: 2rem;
  }

  .footer-btn {
    padding: 0.31rem 1rem 0.31rem 1rem;
    border: none;
    margin-top: 0.1rem;
    font-size: 1.4rem !important;
  }

  .footer-logo {
    width: 15rem;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .footer-input {
    width: 23rem;
    padding: 0.8rem 1rem;
    margin-left: 5rem;
  }

  .footer-btn {
    padding: 1rem 2rem;
    border: none;
  }

  .footer-input-margin {
    margin: 0rem 0rem 0rem 0rem;
  }

  .social-margin {
    margin: 0rem 2rem 0rem 0rem;
  }
}

@media (min-width: 1400px) and (max-width: 1799px) {
  .footer-input-margin {
    margin: 0rem 0rem 0rem 13rem;
  }

  .social-margin {
    margin: 0rem 3rem 0rem 0rem;
  }
}
