/* Governance Section */
.accordion-button:not(.collapsed) {
  background-color: #fff;
  box-shadow: none;
}

.accordion-button:focus {
  border-color: #fff;
  box-shadow: none;
}

/* Key Company Material Section */
.btn-key-company {
  border: 0.1rem solid #f49807;
  padding: 1rem 3rem;
  /* border-radius: 5rem; */
}

.btn-key-company:hover {
  border: 0.1rem solid #989899;
  transition: all 0.5s ease-in-out;
}

/* Where We Work Section */
.where-we-work-video iframe {
  width: 100%;
  height: 35.4rem;
}

/* Solution */
.solution-video iframe {
  width: 100%;
  height: 49.8rem;
}

.partnership-video iframe {
  width: 100%;
  height: 50rem;
}

/* Policies report Card */
.policies-report-card-height {
  height: 27rem;
}

/* /clients/project/id */
.css-1s2u09g-control {
  font-size: 1.6rem;
}

.css-1pahdxg-control {
  font-size: 1.6rem;
}
/* /clients/project/id */

.build-your-project-btn {
  color: white;
  background-color: #f49807;
  border: 1px solid #f49807;
  font-weight: 600;
  transition: all 0.5s ease-in-out;
}

.build-your-project-btn:hover {
  color: #f49807;
  background-color: transparent;
}

@media (max-width: 576px) {
  /* Key Company Material Section */
  .btn-key-company {
    padding: 1rem 1rem;
  }

  /* Where We Work Section */
  .where-we-work-video iframe {
    height: 24rem;
  }

  /* Solution */
  .solution-video iframe {
    height: 23rem;
  }

  .partnership-video iframe {
    height: 22rem;
  }

  /* Policies report Card */
  .policies-report-card-height {
    height: 100%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .where-we-work-video iframe {
    height: 42.4rem;
  }

  /* Solution */
  .solution-video iframe {
    height: 42rem;
  }

  .partnership-video iframe {
    height: 40rem;
  }

  /* Policies report Card */
  .policies-report-card-height {
    height: 22rem;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  /* Policies report Card */
  .policies-report-card-height {
    height: 22rem;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  /* Policies report Card */
  .policies-report-card-height {
    height: 24rem;
  }
}
